import { Injectable } from '@angular/core';
import { IUserAuth } from './interfaces/login.interface';
import { SocialUser } from '@abacritt/angularx-social-login';
import { Role } from '@services/roles/role.dto';

@Injectable({
  providedIn: 'root',
})
export class UserAuthService {
  constructor() {}

  getUserAuth(): IUserAuth | null {
    let user = localStorage.getItem('user');
    if (user != null) {
      return JSON.parse(user) as IUserAuth;
    }
    return null;
  }

  getUserGoogle(): SocialUser | null {
    let user = localStorage.getItem('userGoogle');
    if (user != null) {
      return JSON.parse(user) as SocialUser;
    }
    return null;
  }

  getToken(): string {
    const user = this.getUserAuth();
    return user ? user.token : '';
  }

  getPhoto(): string {
    const userGoogle = this.getUserGoogle();
    if (userGoogle && userGoogle.photoUrl != '') {
      return userGoogle.photoUrl;
    }
    const userAuth = this.getUserAuth();
    const name = userAuth ? userAuth.username : '';
    return `https://ui-avatars.com/api/?name=${name}&background=004C97&color=fff&bold=true&size=128`;
  }

  hasRole(role: string): boolean {
    const user = this.getUserAuth();
    if (user == null) {
      return false;
    }
    const find = user.roles.find((r) => r.name == role);
    return find ? true : false;
  }

  hasPermission(permission: string): boolean {
    const user = this.getUserAuth();
    if (user == null) {
      return false;
    }
    const find = user.permissions.find((p) => p == permission);
    return find ? true : false;
  }

  hasRoles(roles: string[]): boolean {
    const user = this.getUserAuth();
    if (user == null) {
      return false;
    }
    const existing = user.roles.map((role) => role.name);

    return roles.every((role) => existing.includes(role));
  }

  getUserRoles(): Role[] {
    const user = this.getUserAuth();
    if (user == null) {
      return [];
    }
    return user.roles;
  }

  hasSomeRoles(roles: string[]): boolean {
    const user = this.getUserAuth();
    if (user == null) {
      return false;
    }
    const existing = user.roles.map((role) => role.name);

    return roles.some((role) => existing.includes(role));
  }

  hasSomePermissions(permissions: string[]): boolean {
    const user = this.getUserAuth();
    if (user == null) {
      return false;
    }

    const existing = user.permissions;

    return permissions.some((permission) => existing.includes(permission));
  }

  hasSomeProfiles(profiles: string[]): boolean {
    const user = this.getUserAuth();
    if (user === null) return false;

    const existing = user.profile;

    return profiles.some((profile) => existing === profile);
  }
}
